<template>
  <q-expansion-item
    :to="item.to"
    :title="item.to ? `Go to ${item.label}` : `Expand ${item.label}`"
    :clickable="!!item.to"
    :label="item.label"
    :caption="item.caption"
    :content-inset-level="0.15"
    :class="classes"
    :hide-expand-icon="!item.items?.length"
  >
    <q-list
      v-if="item.items?.length"
      class="bg-dark"
    >
      <NavDrawerItem
        v-for="(child, index) in item.items"
        :key="index"
        :item="child"
        :level="level + 1"
      />
    </q-list>

    <template #header>
      <q-item-section>
        <q-item-label>{{ item.label }}</q-item-label>
        <q-item-label
          v-if="item.caption"
          caption
        >
          {{ item.caption }}
        </q-item-label>
      </q-item-section>
    </template>
  </q-expansion-item>
</template>

<script lang="ts" setup>
import type { MenuItem } from '~/types/content';

const props = defineProps({
  item: {
    type: Object as PropType<MenuItem>,
    required: true,
  },
  level: {
    type: Number,
    default: 0,
  },
});

const classes = computed(() => {
  switch (props.level) {
    case 0:
      return ['text-h6', 'text-weight-medium'];
    case 1:
      return ['text-subtitle1', 'text-weight-medium'];
    default:
      return ['text-subtitle2'];
  }
});
</script>

<style lang="scss" scoped>
:deep(.q-expansion-item__content) {
  background: $primary;
}
</style>
