<template>
  <q-card flat>
    <q-card-section horizontal>
      <q-card-section class="q-py-xs">
        <div class="text-caption text-grey">
          {{ item.style }}
        </div>
        <nuxt-link
          :to="`/product/${item.productId}`"
          class="text-subtitle2 text-link"
        >
          {{ item.name }}
        </nuxt-link>
        <div
          v-if="item.canShowPrice"
          class="text-h6 text-weight-light"
        >
          {{ currency(item.total) }}
        </div>
      </q-card-section>

      <q-card-section
        v-if="item.thumbnailUrl"
        class="flex flex-center"
      >
        <q-img
          :src="item.thumbnailUrl"
          :placeholder-src="item.placeholderUrl"
          width="60px"
          ratio="1"
        />
      </q-card-section>
    </q-card-section>

    <q-card-section class="q-py-sm">
      <q-input
        ref="quantity"
        v-model.number="data.quantity"
        :disable="disable"
        :prefix="data.canShowPrice ? `${currency(data.price)} × ` : undefined"
        :rules="[
          (val: number) => !!val || 'Quantity is required.',
          (val: number) => val > 0 || 'Quantity must be positive.',
          (val: number) => val < 1000 || 'Quantity must be less than 1000.',
        ]"
        type="number"
        debounce="350"
        min="1"
        max="999"
        lazy-rules
        dense
        filled
        square
      >
        <template #after>
          <q-btn
            :icon="mdiDeleteOutline"
            :disable="disable"
            color="primary"
            dense
            flat
            round
            @click="$emit('remove', item)"
          />
        </template>
      </q-input>
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { mdiDeleteOutline } from '@quasar/extras/mdi-v7';
import { useVModel } from '@vueuse/core';
import type { QInput } from 'quasar';
import type { CartItemResponse } from '~/types/ecommerce';

const props = defineProps({
  item: {
    type: Object as PropType<CartItemResponse>,
    required: true,
  },
  disable: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits<{
  (event: 'update:item' | 'remove', payload: CartItemResponse): void;
}>();

const quantity = ref<QInput | null>(null);

const data = useVModel(props, 'item', emit, {
  passive: true,
  deep: true,
  shouldEmit: v => quantity.value?.validate(v.quantity) as boolean,
});

const { currency } = useFormatting();
</script>
